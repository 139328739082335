html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--neutral-theme);
}

* {
  -webkit-tap-highlight-color: transparent;
}